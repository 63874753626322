import React, {
    createContext,
    useEffect,
    useState,
    useContext,
    useMemo,
    useCallback,
} from "react";
import { usePrivy } from "@privy-io/react-auth";
import { sepolia } from "wagmi/chains";
import { http, createPublicClient } from "viem";
import { useSmartWallets } from "@privy-io/react-auth/smart-wallets";
import appConfig from "config.json";
// import API from "Utils/API/Backend";
import useGetUserData from "Utils/Hooks/Custom/useGetUserData";

export const WalletContext = createContext({});

// eslint-disable-next-line import/no-mutable-exports
export let logoutWallet = null;

export const WalletProvider = ({ children }) => {
    const [walletClient, setClient] = useState();
    const [account, setAccount] = useState();
    const [isConnecting, setIsConnecting] = useState(false);
    const { ready, authenticated, user, logout } = usePrivy();
    const { client } = useSmartWallets();
    const { token } = useGetUserData();

    const publicClient = useMemo(
        () =>
            createPublicClient({
                chain: sepolia,
                transport: http(appConfig.config.alchemy_rpc_url),
            }),
        []
    );

    const connectWallet = useCallback(async () => {
        if (!token) return;

        try {
            setIsConnecting(true);
            if (!user?.linkedAccounts) {
                throw new Error("No user accounts available");
            }
            const smartWallet = user.linkedAccounts.find(
                (userAccount) => userAccount.type === "smart_wallet"
            );
            if (!smartWallet?.address) {
                throw new Error("No smart wallet found");
            }
            setClient(client);
            setAccount(smartWallet.address);
            console.log("Wallet address", smartWallet.address);

            // Immediately post wallet address after setting it
            // await API({
            //     endpoint: "bank/assign-wallet-address",
            //     method: "POST",
            //     params: { walletAddress: smartWallet.address },
            // });
        } catch (err) {
            console.error("Error connecting wallet:", err);
            throw err;
        } finally {
            setIsConnecting(false);
        }
    }, [user, client, token]);

    const disconnect = useCallback(async () => {
        setAccount(undefined);
        setClient(undefined);
        await logout();
    }, [logout]);

    // const postWalletAddress = async () => {
    //     if (account && token) {
    //         await API({
    //             endpoint: "bank/assign-wallet-address",
    //             method: "POST",
    //             params: { walletAddress: account },
    //         });
    //     }
    // };

    useEffect(() => {
        logoutWallet = disconnect;
    }, [disconnect]);

    // Connect wallet as soon as token and other requirements are available
    useEffect(() => {
        if (token && !account && authenticated && ready && !isConnecting) {
            connectWallet();
        }
    }, [token, account, authenticated, ready, isConnecting, connectWallet]);

    // useEffect(() => {
    //     if (account && token) {
    //         postWalletAddress();
    //     }
    // }, [account, token]);

    const contextValue = useMemo(
        () => ({
            ready,
            authenticated,
            user,
            logout,
            walletClient,
            disconnect,
            connectWallet,
            publicClient,
            isConnecting,
            account,
            token,
        }),
        [
            ready,
            authenticated,
            user,
            logout,
            walletClient,
            disconnect,
            connectWallet,
            publicClient,
            isConnecting,
            account,
            token,
        ]
    );

    return (
        <WalletContext.Provider value={contextValue}>
            {children}
        </WalletContext.Provider>
    );
};

export const useWallet = () => {
    const context = useContext(WalletContext);
    if (!context) {
        throw new Error("useWallet must be used within a WalletProvider");
    }
    return context;
};

export default WalletProvider;

