export const navbarConfig = {
    links: [
        {
            id: 0,
            name: "Home",
            path: "/",
            hasDropDown: false,
        },
        // {
        //     id: 1,
        //     name: "Our features",
        //     path: "/features",
        //     hasDropDown: true,
        //     children: [
        //         {
        //             title: "360 Store experience",
        //             content:
        //                 "Explore products in a 3D environment that allows you to interact as in a physical store",
        //             path: "/features/360-store-experience",
        //         },
        //         {
        //             title: "Influencer opportunities",
        //             content:
        //                 "Earn income just by participating in platform activities",
        //             path: "/features/influencer-opportunities",
        //         },
        //         {
        //             title: "Virtual sales",
        //             content:
        //                 "Ask questions and get information from our virtual sales representatives who are ready to assist",
        //             path: "/features/virtual-sales",
        //         },
        //         {
        //             title: "Data driven optimization",
        //             content:
        //                 " Discover how we harness the power of data to optimize your Metaverse store",
        //             path: "/features/data-driven-optimization",
        //         },
        //     ],
        // },
        // {
        //     id: 2,
        //     name: "Resources",
        //     path: "/resources",
        //     hasDropDown: true,
        //     children: [
        //         {
        //             title: "Blogs",
        //             content:
        //                 "Learn about the latest meetusVR product and company news",
        //             path: "/resources/blogs",
        //         },
        //         {
        //             title: "Events",
        //             content:
        //                 "discover our best events events for the metaverse and enjoy easier shopping",
        //             path: "/resources/events",
        //         },
        //         {
        //             title: "Roadmap",
        //             content:
        //                 "Our roadmap unveils the past and future endeavors of the MeetUsVR team",
        //             path: "/resources/roadmap",
        //         },
        //     ],
        //     additionalContent: {
        //         title: "Find the Deal That Suits You!",
        //         content:
        //             "see our best offers and start improving your store to become better",
        //         textButton: "Learn more",
        //         image: {
        //             alt: "Find the Deal That Suits You!",
        //             src: DropdownImage,
        //         },
        //     },
        // },
        {
            id: 3,
            name: "Influencers",
            path: "/influencers",
            hasDropDown: false,
        },
        // {
        //     id: 4,
        //     name: "Pricing",
        //     path: "/pricing",
        //     hasDropDown: false,
        // },
        {
            id: 5,
            name: "Download app",
            path: "/download-app",
            hasDropDown: false,
        },
        // {
        //     id: 6,
        //     name: "Contact us",
        //     path: "/contact-us",
        //     hasDropDown: false,
        // },
    ],
};

