import "./style.scss";

export default function Loading()
{
    return (
        <div className="loading">
            {Array(3).fill(0).map((_, index) => (<span key={index} className="loading-dot"/>))}
        </div>
    );
}
