import axios from "axios";
import useGetUserData from "../../Hooks/Custom/useGetUserData";

const ERRORS = {
    fallback:
        "An error occurred while processing your request. Please try again later.",
    400: "Oops! Something went wrong with your request.",
    401: "Sorry, you are not authorized to access this resource.",
    402: "Payment is required to proceed.",
    403: "Sorry, you don't have permission to access this resource.",
    404: "Oops! The requested resource was not found.",
    405: "Sorry, the method used is not allowed for this resource.",
    406: "Sorry, the requested resource cannot be served according to the provided preferences.",
    407: "RoutesNavigator with a proxy server is required.",
    408: "The server took too long to respond. Please try again later.",
    409: "Oops! There was a conflict with the current state of the resource.",
    410: "Oops! The requested resource is no longer available.",
    411: "The server requires a Content-Length header.",
    412: "The server preconditions failed for the request.",
    413: "Sorry, the payload of the request is too large.",
    414: "Oops! The provided URL is too long for the server to process.",
    415: "Sorry, the media type of the requested resource is not supported.",
    416: "The requested range is not satisfiable.",
    417: "The server could not meet the expectation specified in the Expect request header.",
    418: "I'm a teapot. This server brews coffee, not tea.",
    421: "Sorry, the request was directed at a server that is not able to produce a response.",
    422: "Oops! The request was well-formed but contains semantic errors.",
    423: "Sorry, the resource is locked and currently unavailable.",
    424: "The request failed due to the failure of a dependent request.",
    425: "Sorry, the server is unwilling to risk processing a request that might be replayed.",
    426: "Upgrade to a different protocol version is required.",
    428: "The server requires the request to be conditional.",
    429: "Oops! You have exceeded the allowed number of requests. Please try again later.",
    431: "The server is unwilling to process the request because the header fields are too large.",
    451: "Sorry, this resource is unavailable due to legal reasons.",
    500: "Oops! Internal Server Error. Please try again later.",
    501: "Sorry, the server does not support the functionality required to fulfill the request.",
    502: "Oops! Bad Gateway. Please try again later.",
    503: "Oops! Service Unavailable. Please try again later.",
    504: "Oops! Gateway Timeout. Please try again later.",
    505: "The server does not support the HTTP protocol version used in the request.",
    506: "The server has detected an infinite loop while processing the request.",
    507: "Sorry, the server has insufficient storage to complete the request.",
    508: "The server detected an infinite loop in the request.",
    510: "Sorry, the server requires further extensions to fulfill the request.",
    511: "Sorry, authentication is required to access this network resource.",
    599: "Oops! Network Connect Timeout Error. Please check your network connection.",
};
const SOURCE = axios.CancelToken.source();

function BaseURL(environment) {
    const baseURLs = {
        development: process.env.REACT_APP_DEVELOPMENT_API_URL,
        production: process.env.REACT_APP_REST_API,
    };

    return baseURLs[environment];
}

/**
 * API function to make requests to the backend server using axios library and cancel token to cancel requests when needed (e.g. when the user navigates to another page) to avoid memory leaks.
 * @param { string } baseURL - Base URL of the backend server (e.g. http://localhost:5000)
 * @param { string } endpoint - Endpoint of the backend server (e.g. /api/users)
 * @param { string } method - HTTP method to be used (e.g. GET, POST, PUT, DELETE)
 * @param { Object } data - Data to be sent to the backend server (e.g. { name: "John Doe", email: "", password: "" })
 * @returns {Promise<unknown>} - Returns a promise with the response from the backend server or an error message if something went wrong.
 * @constructor
 * @example
 * API({ endpoint: "/api/users", method: "GET" }) // GET request to http://localhost:5000/api/users with no data to be sent to the server (e.g. to get all users)
 */
export default function API({
    endpoint,
    method = "GET",
    data = {},
    params = {},
}) {
    const { token } = useGetUserData();
    let baseURL = BaseURL(process.env.REACT_APP_ENVIRONMENT);

    if (!process.env.REACT_APP_ENVIRONMENT) {
        baseURL = BaseURL("production");
    }

    const url = `${baseURL}/${endpoint}`;
    let body = null;

    if (method !== "GET" && method !== "DELETE") {
        body = { ...data };
    }

    const headers = {
        "Content-Type": "application/json",
        accept: "application/json",
        "user-token": token,
    };

    return new Promise((resolve, reject) => {
        axios({
            method,
            url,
            headers,
            data: body,
            params,
            cancelToken: SOURCE.token,
        })
            .then((res) => {
                resolve({
                    message: "",
                    data: res?.response?.data ? res.response.data : { ...res },
                    status: res?.response?.status ? res?.response.status : 200,
                });
            })
            .catch((err) => {
                if (axios.isCancel(err)) {
                    // Handle request cancellation
                    reject({
                        data: {},
                        message: "Request was canceled",
                        status: 0,
                    });
                } else {
                    if (err.response) {
                        // The request was made, but the server responded with a non-2xx status code
                        reject({
                            data: err.response.data || {},
                            message:
                                ERRORS[err.response.status] ||
                                ERRORS["fallback"],
                            status: err.response.status || 500,
                        });
                    } else if (err.request) {
                        // The request was made, but no response was received
                        reject({
                            data: {},
                            message: "No response received from the server",
                            status: 0,
                        });
                    } else {
                        // Something happened in setting up the request that triggered an error
                        reject({
                            data: {},
                            message: ERRORS["fallback"],
                            status: 0,
                        });
                    }
                }
            });
    });
}

export function AbortRequest() {
    SOURCE.cancel("Operation canceled by the user.");
}

