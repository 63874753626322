import CookieManager from "./Services/Storage/Cookie";
import SessionStorageManager from "./Services/Storage/Session";

// New function to get token without hooks
export const getStoredToken = () => {
    const sessionManager = new SessionStorageManager();
    const cookieManager = new CookieManager();
    return sessionManager.get("U_K") || cookieManager.get("U_K");
};

