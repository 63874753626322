import { useEffect, Fragment, useCallback, useState } from "react";
import { ToastContainer } from "react-toastify";
import AOS from "aos";
import "aos/dist/aos.css";
import "react-toastify/dist/ReactToastify.css";

import appConfig from "./config.json";
import Layout from "./Layout";
import API from "./Utils/API/Backend";
import { getStoredToken } from "Utils/auth";
import { PrivyProvider } from "@privy-io/react-auth";
import useGetUserData from "Utils/Hooks/Custom/useGetUserData";
import { SmartWalletsProvider } from "@privy-io/react-auth/smart-wallets";
import WalletProvider from "Components/WalletProvider";
import Loading from "Components/Loading";

export default function MeetusVRApp() {
    const { token } = useGetUserData();
    const [isToken, setIsToken] = useState(null);
    const [isConfigLoaded, setIsConfigLoaded] = useState(false);

    const getCustomAccessToken = useCallback(async () => {
        return getStoredToken();
        // eslint-disable-next-line
    }, [isToken]);

    useEffect(() => {
        const interval = setInterval(() => {
            const storedToken = getStoredToken();
            if (storedToken) {
                setIsToken(storedToken);
                clearInterval(interval);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        async function getAppConfig() {
            try {
                const { data } = await API({
                    endpoint: "frontend/setting?frontend_id=meetus_vr_website",
                });
                appConfig.config = { ...appConfig.config, ...data.data };
                setIsConfigLoaded(true);
            } catch (error) {
                console.error("Error loading config:", error);
            }
        }

        getAppConfig();
    }, []);

    useEffect(() => {
        AOS.init({
            once: true,
        });
    }, []);

    if (!isConfigLoaded) {
        return <Loading />;
    }

    const privyAppId = appConfig.config.bc_privy_app_id;
    if (!privyAppId) {
        console.error("No Privy App ID found!");
        return <div>Configuration Error</div>;
    }

    return (
        <Fragment>
            <PrivyProvider
                appId={privyAppId}
                config={{
                    customAuth: {
                        isLoading: !token,
                        getCustomAccessToken,
                    },
                }}
            >
                <SmartWalletsProvider>
                    <WalletProvider>
                        <Layout />
                        <ToastContainer
                            position="top-right"
                            autoClose={3000}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            limit={4}
                        />
                    </WalletProvider>
                </SmartWalletsProvider>
            </PrivyProvider>
        </Fragment>
    );
}

